import 'bootstrap/dist/css/bootstrap.min.css';
import { CNavbar, CContainer, CNavbarToggler, CCollapse, CNavItem, CNavbarNav, CNavbarBrand } from '@coreui/react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css';

export default function Navbar() {
  const [visible, setVisible] = useState(false);

  return (
    <CNavbar expand="lg" className="bg-white">
      <CContainer fluid>
        <CNavbarToggler
          aria-label="Toggle navigation"
          aria-expanded={visible}
          onClick={() => setVisible(!visible)}
        />
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarBrand href="/"><img className='logo' src={'apple-touch-icon.png'} alt="Logo" /></CNavbarBrand>
          <CNavbarNav className="me-auto mb-2 mb-lg-0">
          <CNavItem>
              <NavLink exact to="/" className="custom-nav-link" activeClassName="active">
                Αρχική
              </NavLink>
            </CNavItem>
            <CNavItem>
              <NavLink exact to="/s3-2-pro" className="custom-nav-link" activeClassName="active">
                Xiaomi Watch S3
              </NavLink>
            </CNavItem>
            <CNavItem>
              <NavLink to="/2-pro" className="custom-nav-link" activeClassName="active">
                Xiaomi Watch 2 | Watch 2 PRO
              </NavLink>
            </CNavItem>
            
            <CNavItem>
              <NavLink to="/4-8-pro" className="custom-nav-link" activeClassName="active">
                Xiaomi Smart Band 8 Pro | Redmi Watch 4
              </NavLink>
            </CNavItem>
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>
  );
}
