import React from 'react';
import './showcase.scss'; // Update to .scss if you changed the extension
import './fonts/fonts.css'; // Adjust the path as necessary
import Footer from './Footer';
import { CCarousel, CCarouselItem, CImage, CCarouselCaption } from '@coreui/react';

import blue from '../src/images/REDMI-WATCH-4/Redmi_Xiaomi_Q-Release-TPU-Strap/blue.png'
import green from '../src/images/REDMI-WATCH-4/Redmi_Xiaomi_Q-Release-TPU-Strap/green.png'
import purple from '../src/images/REDMI-WATCH-4/Redmi_Xiaomi_Q-Release-TPU-Strap/purple.png'
import tpu_blue from '../src/images/REDMI-WATCH-4/Redmi_Xiaomi_Q-Release-TPU-Strap/TPU-Blue.png'
import tpu_yellow from '../src/images/REDMI-WATCH-4/Redmi_Xiaomi_Q-Release-TPU-Strap/TPU-Yellow.png'

import braided_green from '../src/images/REDMI-WATCH-4/Xiaomi-Braided-Q-Release-Strap/Braided-Green.png'
import braided_purple from '../src/images/REDMI-WATCH-4/Xiaomi-Braided-Q-Release-Strap/Braided-Purple.png'

import milanese from '../src/images/REDMI-WATCH-4/Xiaomi-Milanese-Q-Release-Strap/Milanese.png'

import orange from '../src/images/REDMI-WATCH-4/Xiaomi-Leather-Q-Release-Strap/Leather-Orange.png'
import white from '../src/images/REDMI-WATCH-4/Xiaomi-Leather-Q-Release-Strap/Leather-White.png'


import '@coreui/coreui/dist/css/coreui.min.css'
import Navbar from './Navbar';

import { useState } from 'react';
import { useEffect } from 'react';

const Showcase3 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [topImageSrc, setTopImageSrc] = useState('03.jpg');

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
          setTopImageSrc(window.innerWidth < 900 ? '03.jpg' : '03.jpg');
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
      <div className="App">
          <Navbar />
          <header className="App-header">
              <div className="gallery">
                  <div className="full-width-image relative">
                      <img src={topImageSrc} alt="Top Image" />
                  </div>
                  {isMobile ? (
                      <div className="mobile-overlay-text">
                          <h3>Redmi Watch 4 | Xiaomi Smart Band 8 Pro</h3>
                          <p>Πρόσθεσε ευκολία και κομψότητα στην καθημερινότητά σου</p>
                      </div>
                  ) : (
                      <>
                          <h3 className="overlay-text">Redmi Watch 4 | Xiaomi Smart Band 8 Pro</h3>
                          <p className="overlay-text-p">Πρόσθεσε ευκολία και κομψότητα στην καθημερινότητά σου</p>
                      </>
                  )}
              </div>
          </header>

            

<div className='car-cont'>
<div className='carousel-block'>
<CCarousel controls indicators dark>
<CCarouselItem>
    <CImage className="d-block w-100" src={braided_purple} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={braided_green} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Braided Q-Release Strap</h3>
<p>Λουράκι από νάιλον ύφασμα που προσφέρει διαπνοή και είναι κατάλληλο για καλοκαιρινούς μήνες.</p>
</div>


<div className='carousel-block'>
<CCarousel controls indicators dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={orange} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={white} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Leather Q-Release Strap</h3>
<p>Λουράκι από premium δέρμα μόσχου, με λεπτή υφή και ποιότητα.<br></br> Προσαρμόζεται στο χέρι σου και προσθέτει στυλ.</p>
</div>


</div>

<div className='car-cont'>
<div className='carousel-block'>
<CCarousel controls indicators dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={milanese} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Milanese Q-Release Strap</h3>
<p>Λουράκι με λεπτό σχεδιασμό που λάμπει στον καρπό σου, με το μεταλλικό του design να προσθέτει επιπλέον στυλ.</p>
</div>

<div className='carousel-block'>
<CCarousel controls indicators dark>
<CCarouselItem>
    <CImage className="d-block w-100" src={purple} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={green} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={blue} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={tpu_blue} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={tpu_yellow} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Q-Release TPU Strap</h3>
<p>Λουράκι κατασκευασμένο από αντιβακτηριακό υλικό TPU, με δυνατότητα γρήγορης αποδέσμευσης.</p>
</div>
</div>
<Footer />
</div>
    );
}

export default Showcase3;
