import React from 'react';
import './ImageGallery.css'; // Make sure to import the CSS
import './fonts/fonts.css'; // Adjust the path as necessary

const ImageGallery = ({ topImage, images }) => {
  // Texts for specific images
  const captions = {
    1: (
      <div className='cell'>
        <h2>Xiaomi Watch S3</h2>
        <p>Για τη δουλειά, την έξοδο και για κάθε περίσταση</p>
        <button className="image-button"><a href="http://xiaomiwearables.gr/s3-2-pro">Μάθε περισσότερα</a></button>
      </div>
    ),
    2: (
      <div className='cell'>
        <h2>Xiaomi Watch S3, Xiaomi Watch 2</h2>
        <h2>και Watch 2 Pro</h2>
        <p>Συνδύασε την τεχνολογία με την πολυτέλεια</p>
        <button className="image-button"><a href="http://xiaomiwearables.gr/2-pro">Μάθε περισσότερα</a></button>
      </div>
    ),
    5: (
      <div className='cell'>
        <h2>Redmi Watch 4, Smart Brand 8 Pro</h2>
        <p>Πρόσθεσε ευκολία και κομψότητα στην καθημερινότητά σου</p>
        <button className="image-button"><a href="http://xiaomiwearables.gr/4-8-pro">Μάθε περισσότερα</a></button>
      </div>
    ),
  };

  return (
    <div className="gallery">
      <div className="full-width-image relative">
        <img src={topImage} alt="Top Image" />
        <h3 className="overlay-text">Η Xiaomi αναβαθμίζει το look σου!</h3>
        <p className="overlay-text-p">Νέα αξεσουάρ, ακόμα περισσότεροι συνδυασμοί</p>
      </div>
      <div className="centered-text">
        <p>Ανακάλυψε όλα τα νέα μας αξεσουάρ και</p>
        <p>συνδύασε τα με το wearable σου!</p>
      </div>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-cell" style={index === 1 || index === 2 ? { paddingTop: '4rem' } : {}}>
            <img src={image} alt={`Gallery ${index}`} />
            {/* Check if there's a caption for this index */}
            {captions[index] && <p className="image-caption">{captions[index]}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
