import React from 'react';
import './footer.css'; // Make sure to import the CSS
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="custom-footer">
              <div className='container'>
              <NavLink exact to="/" className="custom-nav-link" activeClassName="active">
                  <img src="apple-touch-icon.png" alt="image1"/> 
              </NavLink>

    <div className='logo-cp-1'>
        <span>Copyright © 2022 Infoquest.gr</span>
        <span>Με επιφύλαξη κάθε νόμιμου δικαιώματος.</span> 
    </div>
    </div>
    <div className='logo-cp'>    
        <a href="https://www.infoquest.gr/el/politiki-aporitou">Δήλωση απορρήτου</a>
        <span >|</span>
        <a href="/terms">Όροι χρήσης</a>
        <span >|</span>
        <a href="/cookies">Πολιτική Cookies</a>
        <span >|</span>

        <a href="javascript: Cookiebot.renew()">Προτιμήσεις Cookies</a>
    </div>

  </footer>
  );
};

export default Footer;
