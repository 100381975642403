import React from 'react';
import logo from './logo.svg';
import ImageGallery from './ImageGallery';
import Navbar from './Navbar';
import Footer from './Footer'
const Home = () => {

const topImageUrl = 'Xiaomi_Wearables_Landing_Banner.jpg';
const imageUrls = [
    'Xiaomi_Wearables_Landing_1.png',
    'Xiaomi_Wearables_Landing_2_nobg.png',
    'Xiaomi_Wearables_Landing_3_nobg.png',
    'Xiaomi_Wearables_Landing_3b.png',
    'Xiaomi_Wearables_Landing_4a.png',
    'Xiaomi_Wearables_Landing_4b.png',

  ];

  return (
    <div>
      
        <Navbar/>
        
      <ImageGallery topImage={topImageUrl} images={imageUrls} />
        
      <Footer/>
    </div>
  );
}

export default Home;