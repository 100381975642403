import React from 'react';
import './showcase.scss'; // Update to .scss if you changed the extension
import './fonts/fonts.css'; // Adjust the path as necessary
import Footer from './Footer';
import { CCarousel, CCarouselItem, CImage, CCarouselCaption } from '@coreui/react';


import black_strap_s3 from '../src/images/WATCH-S3/Xiaomi-Watch-Braided-Strap/black.png'

import brown from '../src/images/WATCH-S3/Xiaomi-Watch-Leather-Strap/brown.png'
import orange from '../src/images/WATCH-S3/Xiaomi-Watch-Leather-Strap/orange.png'
import white from '../src/images/WATCH-S3/Xiaomi-Watch-Leather-Strap/white.png'

import green_tpu from '../src/images/WATCH-S3/Xiaomi-Watch-TPU-Strap/green.png'
import orange_tpu from '../src/images/WATCH-S3/Xiaomi-Watch-TPU-Strap/orange.png'
import pine_green_tpu from '../src/images/WATCH-S3/Xiaomi-Watch-TPU-Strap/pine-green.png'

import strap_blue from '../src/images/WATCH-S3/Xiaomi-Watch-Strap/blue.png'
import strap_dual_tone_ceramic from '../src/images/WATCH-S3/Xiaomi-Watch-Strap/dual-tone-ceramic.png'
import strap_rainbow from '../src/images/WATCH-S3/Xiaomi-Watch-Strap/rainbow.png'
import strap_yellow from '../src/images/WATCH-S3/Xiaomi-Watch-Strap/yellow.png'
import { useState } from 'react';
import { useEffect } from 'react';

import '@coreui/coreui/dist/css/coreui.min.css'
import Navbar from './Navbar';

const Showcase2 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [topImageSrc, setTopImageSrc] = useState('02.jpg');

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
          setTopImageSrc(window.innerWidth < 900 ? '02.jpg' : '02.jpg');
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
      <div className="App">
          <Navbar />
          <header className="App-header">
              <div className="gallery">
                  <div className="full-width-image relative">
                      <img src={topImageSrc} alt="Top Image" />
                  </div>
                  {isMobile ? (
                      <div className="mobile-overlay-text">
                          <h3>Xiaomi Watch S3 | Xiaomi Watch 2 | Xiaomi Watch 2 Pro</h3>
                          <p>Συνδύασε την τεχνολογία με την πολυτέλεια</p>
                      </div>
                  ) : (
                      <>
                          <h3 className="overlay-text">Xiaomi Watch S3 | Xiaomi Watch 2 |</h3>
                          <h3 className='overlay-text mt-5'>Xiaomi Watch 2 Pro</h3>
                          <p className="overlay-text-p mt-5">Συνδύασε την τεχνολογία με την πολυτέλεια</p>
                      </>
                  )}
              </div>
          </header>

            

<div className='car-cont'>
            

<div className='carousel-block'>
  <CCarousel controls indicators dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={black_strap_s3} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">

    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Watch Braided Strap</h3>
<p>Εντυπωσιακό λουράκι, φτιαγμένο από ποιοτικά και φιλικά προς το δέρμα υλικά, κατάλληλο για ρολόγια με λουράκι μεγέθους 22mm.</p>
</div>

<div className='carousel-block'>
<CCarousel controls indicators dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={green_tpu} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={orange_tpu} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={pine_green_tpu} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Watch TPU Strap</h3>
<p>Στυλάτο και κομψό λουράκι, από ανακυκλωμένα υλικά κατάλληλο, για ρολόγια με λουράκι μεγέθους 22mm.</p>
</div>

            </div>


            

<div className='car-cont'>


<div className='carousel-block'>
<CCarousel controls indicators dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={brown} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={orange} alt="slide 2" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={white} alt="slide 3" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Leather Strap</h3>
<p>Εντυπωσιακό premium λουράκι, με φυσική λάμψη, κατάλληλο για κάθε περίσταση.</p>
</div>

<div className='carousel-block'>
<CCarousel controls indicators dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={strap_blue} alt="slide 1" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={strap_dual_tone_ceramic} alt="slide 2" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={strap_rainbow} alt="slide 3" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={strap_yellow} alt="slide 3" />
    <CCarouselCaption className="d-none d-md-block">
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel>
<h3>Xiaomi Leather Strap</h3>
<p>Εντυπωσιακό premium λουράκι, με φυσική λάμψη, κατάλληλο για κάθε περίσταση.</p>
</div>


</div>

            <Footer />
        </div>
    );
}

export default Showcase2;
