//src/App.js
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import NotFoundPage from './NotFoundPage';
import Home from './Home';
import Privacy from './Privacy';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Terms from './Terms'
import Cookies from './Cookies';
import Showcase from './Showcase';
import Showcase2 from './Showcase2';
import Showcase3 from './Showcase3';




const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/s3-2-pro' element={<Showcase/>}></Route>
        <Route path='/2-pro' element={<Showcase2/>}></Route>
        <Route path='/4-8-pro' element={<Showcase3/>}></Route>

        <Route path='/terms' element={<Terms/>}></Route>
        <Route path='/cookies' element={<Cookies/>}></Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
    
  );
}

export default App;
