import Footer from './Footer';
import './privacy.css'
import Navbar from './Navbar';
import { useState } from 'react';
import React, { useEffect, useRef } from 'react';

const Cookies = () => {
  const linkRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      event.preventDefault();
      if (window.CookieConsent && window.CookieConsent.dialog) {
        window.CookieConsent.dialog.open();
        console.log('Opening Cookiebot dialog.');
      } else {
        console.error('CookieConsent is not available.');
      }
    };

    if (linkRef.current) {
      linkRef.current.addEventListener('click', handleClick);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (linkRef.current) {
        linkRef.current.removeEventListener('click', handleClick);
      }
    };
  }, []);
  return (
    <div>
        <Navbar/>
    <div className='content'>
         <h2 className='title'>Πολιτική Cookies</h2>
         <h3>Πληροφορίες σχετικά με την Πολιτική των Cookies</h3>
         <p>Ενημερωθείτε εδώ και λάβετε όλες τις σχετικές πληροφορίες για την Πολιτική των Cookies
Η παρούσα πολιτική cookies εξηγεί τι είναι τα cookies και πώς τα χρησιμοποιούμε στον ιστότοπό μας <a href="https://xiaomiwearables.gr/">xiaomiwearables.gr</a>. Σας προτείνουμε να αφιερώσετε λίγο χρόνο και να διαβάσετε την πολιτική, ώστε να μπορείτε να κατανοήσετε τους τύπους των Cookies που χρησιμοποιούμε, τη λειτουργία τους, τις πληροφορίες που συλλέγουμε μέσω των Cookies καθώς και πώς χρησιμοποιούνται  οι πληροφορίες αυτές . 
</p>
<h3>Τι είναι τα Cookies;</h3>
<p>Τα «cookies» είναι μικρά αρχεία με πληροφορίες που μια ιστοσελίδα αποθηκεύει στην τερματική συσκευή (π.χ. υπολογιστή, tablet, κινητό τηλέφωνο κλπ)  ενός χρήστη (συνήθως στο πρόγραμμα περιήγησης ιστού όπως Chrome, Opera, Mozilla Firefox, Edge, etc), ώστε κάθε φορά που ο χρήστης συνδέεται στην ιστοσελίδα, η τελευταία να ανακτά τις εν λόγω πληροφορίες και να προσφέρει στον χρήστη σχετικές με αυτές υπηρεσίες. Χαρακτηριστικό παράδειγμα τέτοιων πληροφοριών είναι οι προτιμήσεις του χρήστη σε μια ιστοσελίδα, όπως αυτές δηλώνονται από τις επιλογές που κάνει ο χρήστης στη συγκεκριμένη ιστοσελίδα (π.χ. επιλογή συγκεκριμένων «κουμπιών», αναζητήσεων, διαφημίσεων κλπ).</p>
<h3>Πληροφορίες για τη διαχείριση των Cookies</h3>
<p>Κατά την είσοδό σας στον ιστότοπό μας μπορείτε να διαχειριστείτε τις προτιμήσεις σας σχετικά με τα cookies, επιλέγοντας στο Cookie banner που εμφανίζεται στο κάτω μέρος της οθόνης της ιστοσελίδας, ποια cookies θα είναι ενεργοποιημένα ή απενεργοποιημένα κατά τις επισκέψεις σας στην ιστοσελίδα μας.
Ο ιστότοπός μας χρησιμοποιεί τα τεχνικά αναγκαία Cookies, για την ορθή και αποτελεσματική λειτουργία του. Για τη λειτουργία των αναγκαίων Cookies δεν απαιτείται η συγκατάθεση του χρήστη. Μπορείτε να συνεχίσετε την επίσκεψή σας με τη χρήση μόνο των αναγκαίων cookies κάνοντας κλικ στο κουμπί ΑΠΟΡΡΙΨΗ στο Cookie banner.
</p>
<p>Εφόσον επιθυμείτε να συναινέσετε και στη χρήση των Cookies Στατιστικών (προαιρετικά Cookies) μπορείτε να δηλώσετε την επιλογή σας στο Cookies banner που εμφανίζεται στο κάτω μέρος της οθόνης της ιστοσελίδας μας, πατώντας στο μενού Επιλογές Cookies , κι αφού επιλέξετε την κατηγορία Cookies Στατιστικών στη συνέχεια κάνετε κλικ στο κουμπί ΑΠΟΔΟΧΗ ΕΠΙΛΟΓΗΣ, για να αποθηκεύσετε την επιλογή σας και να συνεχίσετε την επίσκεψή σας. </p>
<p>Μπορείτε ανά πάσα στιγμή να τροποποιήσετε τις προτιμήσεις σας για τα Cookies (εκτός από τα τεχνικώς αναγκαία Cookies). Η διαχείριση των προτιμήσεών σας για τα Cookies μπορεί να γίνει μέσω του Cookie banner, το οποίο είναι διαθέσιμο κατά την είσοδό σας στον ιστότοπό μας,στο εικονίδιο στο κάτω μέρος της οθόνης της ιστοσελίδας ή κάνοντας κλικ  <a href="javascript: Cookiebot.renew()">ΕΔΩ</a></p>    
<h3>Κατηγορίες Cookies</h3>
<h4>1. Αναγκαία</h4>
<p>Τα αναγκαία Cookies είναι απαραίτητα για να λειτουργήσει σωστά ο ιστότοπός μας και να εξυπηρετήσει το σκοπό την επίσκεψης του χρήστη. Τα cookies αυτής της κατηγορίας επιτρέπουν τις βασικές λειτουργίες του ιστοτόπου, όπως την πρόσβαση και την πλοήγηση στην ιστοσελίδα, την αποθήκευση των προτιμήσεων του χρήστη σε σχέση με τη χρήση των προαιρετικών cookies.</p>
<h4>2. Στατιστικών</h4>  
<p>Τα στατιστικά Cookies μας δίνουν τη δυνατότητα να κατανοήσουμε πώς χρησιμοποιούν οι επισκέπτες μας τον ιστότοπό μας, συλλέγοντας και αναφέροντας ανώνυμα πληροφορίες. Έτσι, αξιολογούμε την αποτελεσματικότητα των λειτουργιών του ιστοτόπου ώστε, μέσω της δημιουργίας χρήσιμων reports, να βελτιώνουμε συνεχώς την περιήγησή σας στον ιστότοπό μας.</p>
<h4>Πώς να ελέγξετε τα cookies</h4>
<p>Μπορείτε να ρυθμίσετε το πρόγραμμα της περιήγησή σας στο διαδίκτυο κατά τέτοιο τρόπο ώστε να σας προειδοποιεί για τη χρήση των Cookies και να μην επιτρέπει την αποδοχή τους σε καμία περίπτωση. Στην περίπτωση αυτή ορισμένες υπηρεσίες και λειτουργίες ενδέχεται να μη λειτουργούν. </p>
<p>Μπορείτε ακόμα να διαγράψετε όλα τα Cookies που βρίσκονται ήδη στη συσκευή σας διαγράφοντας το ιστορικό περιήγησης του προγράμματος περιήγησής σας. Με αυτόν τον τρόπο θα διαγραφούν όλα τα Cookies από όλους τους ιστοτόπους που  επισκεφθήκατε από το συγκεκριμένο πρόγραμμα περιήγησης. Να έχετε όμως υπόψη ότι ενδέχεται να χαθούν και ορισμένα αποθηκευμένα στοιχεία (π.χ. αποθηκευμένα στοιχεία σύνδεσης, προτιμήσεις ιστοτόπου).</p>
<p>Επιπρόσθετα, μπορείτε να εμποδίσετε την Google να συλλέγει δεδομένα μέσω των cookies και την επακόλουθη επεξεργασία των δεδομένων, μεταφορτώνοντας και εγκαθιστώντας την προσθήκη για το πρόγραμμα περιήγησης από την ακόλουθη διεύθυνση: <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>.</p>
<p>Για να διαχειριστείτε και να απενεργοποιήσετε τα Cookies, μπορείτε να ακολουθήσετε τις σχετικές οδηγίες για κάθε τύπο προγράμματος περιήγησης.</p>
<h4>Google Analytics </h4>
<p>Στον ιστότοπο μας  χρησιμοποιούμε τα Google Analytics.
Ειδικότερα, όταν επισκέπτεστε τον ιστότοπό μας, εφόσον έχετε αποδεχτεί στις ρυθμίσεις Cookies τα στατιστικά cookies, τα Google Analytics καταγράφουν μεταξύ άλλων την τοποθεσία σας, τις αναζητήσεις σας και δεδομένα από ιστοτόπους που συνεργάζονται με την Google, ενώ χρησιμοποιούνται για την παροχή συγκεντρωτικών και ανωνυμοποιημένων πληροφοριών σχετικά με τις συμπεριφορές των χρηστών μεταξύ συσκευών, όπως για παράδειγμα δημογραφικές πληροφορίες (φύλο, ηλικιακή ομάδα, πληροφορίες σχετικά με τα ενδιαφέροντα που εκφράζετε μέσα από τις δραστηριότητές σας στο διαδίκτυο).
</p>
<p>Εφόσον έχετε ενεργοποιήσει την Εξατομίκευση διαφημίσεων στις ρυθμίσεις του λογαριασμού σας στην Google , τα δεδομένα αυτά συλλέγονται και συνδέονται με τον εν λόγω λογαριασμό σας. Με αυτόν τον τρόπο, η Google μπορεί να αναγνωρίσει, για παράδειγμα, εάν βλέπετε ένα προϊόν στον ιστότοπο μέσω smartphone. </p>
<p>Αν επιθυμείτε να απενεργοποιήσετε αυτή τη λειτουργία, μπορείτε να το κάνετε μέσω του Google λογαριασμού σας. Για περισσότερες πληροφορίες σχετικά με τον τρόπο προσαρμογής των ρυθμίσεων διαφήμισης της Google, παρακαλούμε επισκεφθείτε τη διεύθυνση <a href="https://support.google.com/ads/answer/2662856?hl=el">https://support.google.com/ads/answer/2662856?hl=el</a>.</p> 
<p>Οι ακόλουθες σελίδες παρέχουν τις οδηγίες για την ρύθμιση των cookies στα πιο γνωστά προγράμματα περιήγησης στο web:</p>
<ul>
    <li><a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d">Cookie settings in Internet Explorer</a></li>
    <li><a href="https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US">Cookie settings in Firefox</a></li>
    <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Cookie settings in Chrome</a></li>
    <li><a href="https://support.apple.com/safari">Cookie settings in Safari</a></li>
</ul>



<h4>Αναλυτικός Πίνακας Cookies στο  <a href="https://xiaomiwearables.gr/">xiaomiwearables.gr/</a></h4>

<h4>Αναγκαία(2)</h4>
<p>Τα απαραίτητα cookies βοηθούν στο να γίνει χρηστική μία ιστοσελίδα, επιτρέποντας βασικές λειτουργίες όπως την πλήγηση και την πρόσβαση σε ασφαλείς περιοχές της ιστοσελίδας. Η ιστοσελίδα δεν μπορεί να λειτουργήσει σωστά χωρίς αυτά τα cookies.</p>
<div className='cookie-board'>
<table>
    <tr className='titles'>
        <td>Cookie Domain</td>
        <td>Cookie Name</td>
        <td>Σκοπός</td>
        <td>Πολιτική Απορρήτου</td>
        <td>Λήξη</td>
        <td>Τύπος</td>
        <td>Αποδέκτης (Επωνυμία)</td>
        <td>Αποδέκτης (website)</td>
    </tr>
    <tr>
        <td>1.gif</td>
        <td>imgsct.cookiebot.com</td>
        <td>Χρησιμοποιείται για να μετράει τις συνεδρίες σε μία ιστοσελίδα, απαραίτητο για την βελτιστοποίηση παράδοσης προϊόντων CMP.</td>
        <td>cookiebot.com/en/privacy-policy/</td>
        <td>Session</td>
        <td>Pixel Tracker</td>
        <td>Cookiebot</td>
        <td>Cookiebot.com</td>
    </tr>
    <tr>
        <td>CookieConsent</td>
        <td>consent.cookiebot.com</td>
        <td>Αποθηκεύει την κατάσταση συγκατάθεσης του χρήστη για τον συγκεκριμένο τομέα.</td>
        <td>cookiebot.com/en/privacy-policy/</td>

        <td>1 έτος</td>
        <td>HTTP Cookie</td>
        <td>Cookiebot</td>
        <td>Cookiebot.com</td>
    </tr>
</table>

</div>
<h4 className='marketing-cookies'>Στατιστικών(2)</h4>
<p>Τα cookies Εμπορικής Προώθησης χρησιμοποιούνται για την παρακολούθηση των επισκεπτών στους ιστότοπους. Η πρόθεση είναι να εμφανίσουμε διαφημίσεις που είναι σχετικές και ελκυστικές για τους χρήστες και ως εκ τούτου πιο πολύτιμες για τρίτους εκδότες και διαφημιστές.</p>

<div className='cookie-board'>
<table>
    <tr className='titles'>
        <td>Cookie Domain</td>
        <td>Cookie Name</td>
        <td>Σκοπός</td>
        <td>Πολιτική Απορρήτου</td>
        <td>Λήξη</td>
        <td>Τύπος</td>
        <td>Αποδέκτης (Επωνυμία)</td>
        <td>Αποδέκτης (website)</td>
    </tr>
    <tr>
        <td>_ga </td>
        <td>www.googletagmanager.com</td>
        <td>policies.google.com/privacy?hl=el</td>
        <td>Χρησιμοποιείται για να στείλει δεδομένα στο Google Analytics σχετικά με την συσκευή του χρήστη και την συμπεριφορά του στον ιστότοπο. Εντοπίζει τον επισκέπτη μέσω συσκευών και καναλιών διαφήμισης.</td>
        <td>2 έτη</td>
        <td>HTTP Cookie</td>
        <td>Google</td>
        <td>Google.com</td>
    </tr>
    <tr>
        <td>_ga_#</td>
        <td>www.googletagmanager.com</td>
        <td>policies.google.com/privacy?hl=el/</td>
        <td>Χρησιμοποιείται για να στείλει δεδομένα στο Google Analytics σχετικά με την συσκευή του χρήστη και την συμπεριφορά του στον ιστότοπο. Εντοπίζει τον επισκέπτη μέσω συσκευών και καναλιών διαφήμισης.</td>
        <td>2 έτη</td>
        <td>HTTP Cookie</td>
        <td>Google</td>
        <td>Google.com</td>
    </tr>
</table>

</div>
   
    </div>
    <Footer/>
    </div>
  );
};

export default Cookies;
